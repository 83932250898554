<template>
  <section id="header_main" class="clearfix">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 space_all">
          <div class="col-sm-5 space_all">
            <div class="header_main_1">
              <ul>
                <li><a href="/" class="hvr-wobble-to-top-right">Home</a></li>
                <li>
                  <a href="/about" class="hvr-wobble-to-top-right">About us</a>
                </li>
                <li>
                  <a href="contact" class="hvr-wobble-to-top-right">Contacts</a>
                </li>
                <li>
                  <a href="/blog" class="hvr-wobble-to-top-right">Blog</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-2 space_all">
            <div class="header_main_2">
              <div id="custom-search-input">
                <div class="input-group col-md-12">
                  <input
                    type="text"
                    class="search-query form-control"
                    placeholder="Search"
                  />
                  <span class="input-group-btn">
                    <button class="btn btn-danger" type="button">
                      <span class="glyphicon glyphicon-search"></span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-5 space_all">
            <div class="header_main_3">
              <ul>
                <li class="well_4"><a href="#">AT QUIS (0)</a></li>
                <li class="well_5"><a href="#">|</a></li>
                <li class="well_3">
                  <a href="#"><i class="fa fa-shopping-cart"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="header" class="cd-secondary-nav">
    <div class="container">
      <div class="row">
        <nav class="navbar navbar-default" role="navigation">
          <!-- Brand and toggle get grouped for better mobile display -->
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#dropdown-thumbnail-preview"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="index.html"
              ><span class="well_2"><i class="fa fa-microphone"></i></span> KIGALI TOAST FEST<span class="well_1"></span
            ></a>
          </div>
          <!-- Collect the nav links, forms, and other content for toggling -->
          <div class="collapse navbar-collapse" id="dropdown-thumbnail-preview">
            <ul class="nav navbar-nav">
              <li class="active">
                <a href="/" class="hvr-wobble-to-top-right">HOME</a>
              </li>
              <li>
                <a href="/about" class="hvr-wobble-to-top-right">ABOUT US</a>
              </li>
              <li><a href="/blog" class="hvr-wobble-to-top-right">BLOG</a></li>
              <li>
                <a href="/event" class="hvr-wobble-to-top-right">EVENT</a>
              </li>
              <li>
                <a href="/contact" class="hvr-wobble-to-top-right">CONTACT</a>
              </li>
              <li class="dropdown">
                <a
                  href="#"
                  class="hvr-wobble-to-top-right"
                  data-toggle="dropdown"
                  >DROPDOWN <b class="caret"></b
                ></a>
                <ul class="dropdown-menu">
                  <li>
                    <a href="#" class="hvr-wobble-to-top-right"
                      >LOGIN ADMIN</a
                    >
                  </li>
                  <!-- <li>
                    <a href="about us.html" class="hvr-wobble-to-top-right"
                      >ABOUT US</a
                    >
                  </li>
                  <li>
                    <a
                      href="product details.html"
                      class="hvr-wobble-to-top-right"
                      >PRODUCT DETAILS</a
                    >
                  </li>
                  <li>
                    <a href="details.html" class="hvr-wobble-to-top-right"
                      >DETAILS</a
                    >
                  </li> -->
                </ul>
              </li>
            </ul>
          </div>
          <!-- /.navbar-collapse -->
        </nav>
      </div>
    </div>
  </section>
</template>
