<template>
<HeaderItem />
	<section id="center">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="col-sm-6"></div>
					<div class="col-sm-6">
						<div class="center_1">
							<h6>Show Me Your Talent</h6>
							<h1>SHARE YOUR TALENT <br> WITH PEOPLE</h1>
							<p><a href="#">VIEW NOW!</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section id="middle">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 space_all">
					<div class=" content-top2">
						<h2 class="text-center">EVENTS</h2>
						<div class="home-doctors  clearfix">
							<div class="container">
								<div class="row">
									<!-- entry1 -->
									<div class="col-lg-3 col-sm-3 col-sm-6  text-center doc-item">
										<div class="common-doctor animated fadeInUp clearfix ae-animation-fadeInUp">

											<ul class="list-inline social-lists animate">
												<li><a href="/event-details"><i class="fa fa-search-plus"></i></a>
												</li>
											</ul>
											<figure>
												<img width="670" height="500" src="img/listing 1.jpeg"
													class="doc-img animate attachment-gallery-post-single wp-post-image"
													alt="doctor-2">
											</figure>
											<div class="text-content">
												<h5><a href="event-details">MICHIGAN SHOW ME YOUR TALENT</a></h5>
												<!-- <div class="for-border"></div> -->
												<h4>$1,234.11</h4>
												<p><a href="event-details">VIEW Details</a></p>
											</div>
										</div>
									</div>
									<!-- entry2 -->
									<div class="col-lg-3 col-sm-3 col-sm-6  text-center doc-item">
										<div class="common-doctor animated fadeInUp clearfix ae-animation-fadeInUp">
											<ul class="list-inline social-lists animate">
												<li><a href="/event-details"><i class="fa fa-search-plus"></i></a>
												</li>
											</ul>

											<figure>
												<img width="670" height="500" src="img/listing 2.jpeg"
													class="doc-img animate attachment-gallery-post-single wp-post-image"
													alt="doctor-2">
											</figure>

											<div class="text-content">
												<h5><a href="/event-details">ARIZONA SHOW ME YOUR TALENT</a></h5>
												<!-- <div class="for-border"></div> -->
												<h4>$1,234.11</h4>
												<p><a href="/event-details">VIEW Details</a></p>
											</div>
										</div>
									</div>

									<!-- entry3 -->
									<div class="col-lg-3 col-sm-3 col-sm-6  text-center doc-item">
										<div class="common-doctor animated fadeInUp clearfix ae-animation-fadeInUp">

											<ul class="list-inline social-lists animate">

												<li><a href="/event-details"><i class="fa fa-search-plus"></i></a>
												</li>
											</ul>

											<figure>
												<img width="670" height="500" src="img/listing 3.jpeg"
													class="doc-img animate attachment-gallery-post-single wp-post-image"
													alt="doctor-2">
											</figure>

											<div class="text-content">
												<h5><a href="event-details">TEXAS SHOW ME YOUR TALENT</a></h5>
												<!-- <div class="for-border"></div> -->
												<h4>$1,234.11</h4>
												<p><a href="/event-details">VIEW Details</a></p>
											</div>
										</div>
									</div>
									<!-- entry4 -->
									<div class="col-lg-3 col-sm-3 col-sm-6  text-center doc-item">
										<div class="common-doctor animated fadeInUp clearfix ae-animation-fadeInUp">

											<ul class="list-inline social-lists animate">
												<li><a href="/event-details"><i class="fa fa-search-plus"></i></a>
												</li>
											</ul>

											<figure>
												<img width="670" height="500" src="img/listing 4.jpeg"
													class="doc-img animate attachment-gallery-post-single wp-post-image"
													alt="doctor-2">
											</figure>

											<div class="text-content">
												<h5><a href="/event-details">KENTAKY SHOW ME YOUR TALENT</a></h5>
												<!-- <div class="for-border"></div> -->
												<h4>$1,234.11</h4>
												<p><a href="/event-details">VIEW Details</a></p>
											</div>
										</div>
									</div>
									<!-- // -->
									<div class="visible-sm clearfix margin-gap"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section id="Customer" class="clearfix">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="Customer_1">
						<h2 class="text-center">ABOUT SHOW ME YOUR TALENT</h2>
						<h4 class="text-center">Grow your best Talent through People !</h4>
						<p class="text-center">we can truly 'Grow your best Talent through People.' It's not just about individual growth but also about leveraging the collective strengths and experiences of our diverse community, encouraging each person to showcase their talents and contribute to our shared success.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section id="Clearance" class="clearfix">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="col-sm-4 clearfix">
						<div class="Clearance_1 clearfix">
							<a class="sb" href="img/41.jpg"><img src="img/about 3.jpeg" width="100%"></a><br>
							<h2>Traditional Talent</h2>
							<p>Quis sem at nibh elementum.</p>
						</div>
					</div>
					<div class="col-sm-4 clearfix">
						<div class="Clearance_1 clearfix">
							<a class="sb" href="img/42.jpg"><img src="img/about 1.jpeg" width="100%"></a><br>
							<h2>Modern Talent</h2>
							<p>Quis sem at nibh elementum.</p>
						</div>
					</div>
					<div class="col-sm-4 clearfix">
						<div class="Clearance_1 clearfix">
							<a class="sb" href="img/43.jpg"><img src="img/about 2.jpeg" width="100%"></a><br>
							<h2>Music Singer</h2>
							<p>Quis sem at nibh elementum.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- <section id="Deals" class="clearfix">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="Deals_1">
						<h2 class="text-center">FEATURED ALBUMS</h2>
					</div>
					<div class="col-sm-4">
						<div class="Deals_2">
							<h2><a href="#"><img src="img/44.jpg" width="100%"></a></h2>
							<h3 class="text-center"><a href="#">HIGH FIVE</a></h3>
							<h4 class="text-center">The album reached top places in charts in just 2 days. The most
								promising record of the year.</h4>
							<p class="text-center"><a href="#">READ MORE</a></p>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="Deals_2">
							<h2><a href="#"><img src="img/45.jpg" width="100%"></a></h2>
							<h3 class="text-center"><a href="#">SHAKING HANDS</a></h3>
							<h4 class="text-center">The album reached top places in charts in just 2 days. The most
								promising record of the year.</h4>
							<p class="text-center"><a href="#">READ MORE</a></p>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="Deals_2">
							<h2><a href="#"><img src="img/46.jpg" width="100%"></a></h2>
							<h3 class="text-center"><a href="#">EVERYTHING DAMAGED</a></h3>
							<h4 class="text-center">The album reached top places in charts in just 2 days. The most
								promising record of the year.</h4>
							<p class="text-center"><a href="#">READ MORE</a></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> -->
	<section id="work" class="clearfix">
		<div class="work_main clearfix">
			<div class="container">
				<div class="row">
					<div class="work_1">
						<h2 class="text-center">A GREAT WAY TO SHARE TALENT WITH PEOPLE</h2>
						<h4 class="text-center">Are you a music producer? Help us to make our events brighter and
							collaborate with us.<br> We love music of all genres.</h4>
						<p class="text-center"><a href="#">GET FREE PROPOSALS</a></p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section id="testimonials" class="clearfix">
		<div class="container">
			<div class="row">
				<div class="testimonials_6_top clearfix">
					<h1>Curabitursodales ligula in libero</h1>
					<h2>Sed dignissim lacinia</h2>
					<div class="col-sm-12 testimonials_6_top_2 clearfix">
						<div class="carousel slide" id="fade-quote-carousel" data-ride="carousel" data-interval="3000">
							<!-- Carousel indicators -->
							<ol class="carousel-indicators">
								<li data-target="#fade-quote-carousel" data-slide-to="0" class=""></li>
								<li data-target="#fade-quote-carousel" data-slide-to="1" class=""></li>
								<li data-target="#fade-quote-carousel" data-slide-to="2" class="active"></li>
								<li data-target="#fade-quote-carousel" data-slide-to="3"></li>
								<li data-target="#fade-quote-carousel" data-slide-to="4"></li>
								<li data-target="#fade-quote-carousel" data-slide-to="5"></li>
							</ol>
							<!-- Carousel items -->
							<div class="carousel-inner">
								<div class="item">
									<div class="profile-circle" style="background-color: rgba(0,0,0,.2);"></div>
									<blockquote>
										<p>Consectetur adipiscing elit. Integer nec odionec odio. Praesent libero. Sed
											cursus ante dapibus diam. Sed nisi. Nulla Praesent mauris. Fusce nec tellus
											sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.
										</p>
										<h5>Duis sagittis</h5>
									</blockquote>
								</div>
								<div class="item">
									<div class="profile-circle" style="background-color: rgba(77,5,51,.2);"></div>
									<blockquote>
										<p>Ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis
											sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta.
											Mauris massa. Vestibulum lacinia arcu eget .Sed dignissim lacinia nunc.</p>
										<h5>Adipiscing Lacinia</h5>
									</blockquote>
								</div>
								<div class="item active">
									<div class="profile-circle" style="background-color: rgba(145,169,216,.2);"></div>
									<blockquote>
										<p>Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper
											porta. Mauris massa. Vestibulum lacinia arcu eget nulla.Class aptent taciti
											sociosqu ad litora torquent odionec odio. Praesent libero. Sed cursus ante
											dapibus</p>
										<h5>Praesent Libero</h5>
									</blockquote>
								</div>
								<div class="item">
									<div class="profile-circle" style="background-color: rgba(77,5,51,.2);"></div>
									<blockquote>
										<p>Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper
											porta. Mauris massa. Vestibulum lacinia arcu eget nulla.Class aptent taciti
											sociosqu ad litora torquent odionec odio. Praesent libero. Sed cursus ante
											dapibus</p>
										<h5>Praesent Libero</h5>
									</blockquote>
								</div>
								<div class="item">
									<div class="profile-circle" style="background-color: rgba(77,5,51,.2);"></div>
									<blockquote>
										<p>Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent
											mauris. Fusce nec tellus sed augue .Class aptent taciti sociosqu ad litora
											torquent per conubia nostra, per inceptos C. Curabitursodales ligula in
											libero.</p>
										<h5>Vestibulum Lacinia</h5>
									</blockquote>
								</div>
								<div class="item">
									<div class="profile-circle" style="background-color: rgba(77,5,51,.2);"></div>
									<blockquote>
										<p>Consectetur adipiscing elit. Integer nec odionec odio. Praesent libero. Sed
											cursus ante dapibus diam. Sed nisi. Nulla Praesent mauris. Fusce nec tellus
											sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.
										</p>
										<h5>Duis sagittis</h5>
									</blockquote>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<FooterItem />
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    HeaderItem,
    FooterItem
  }
}
</script>
