<template>
  <section id="footer" class="clearfix">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="col-sm-3">
            <div class="footer_1">
              <h4>KIGALI TOAST FEST</h4>
              <p>
                we can truly 'Grow your best Talent through People.' It's not
                just about individual growth but also about leveraging the
                collective strengths and experiences of our diverse community,
                encouraging each person to showcase their talents and contribute
                to our shared success
              </p>
              <ul>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-google-plus"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-instagram"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="footer_2">
              <h4>QUICK LINKS</h4>
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">Abut Talent</a></li>
                <li><a href="#">Blog Talent</a></li>
                <li><a href="#">Event Talent</a></li>
                <!-- <li><a href="#">NEC</a></li>
                <li><a href="#">Ante</a></li> -->
              </ul>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="footer_3">
              <h4>NEWS LETTER</h4>
              <p>
                Torquent per conubia nostra, per inceptos Curabitursodales
                ligula in libero.Sed.
              </p>
              <h4>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter your email address"
                />
              </h4>
              <h5><a href="#">SEND</a></h5>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="footer_4">
              <h4>CONTACT US</h4>
              <ul>
                <li>
                  <i class="fa fa-map-marker"></i>Address: Grand Rapids MI
                  49508, United States
                </li>
                <li>
                  <i class="fa fa-phone"></i>Phones:
                  <a href="#">+161-6500-3011</a>
                </li>
                <li>
                  <i class="fa fa-user"></i>Fusce Nec Tellus Sed Augue Semper
                  Porta.Mauris
                </li>
                <li>
                  <i class="fa fa-envelope"></i>E-mail:<a href="#">
                    info@gmail.com</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="footer_main" class="clearfix">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="footer_main_1">
            <p>
              © 20123 Show me Your Talent. All Rights Reserved. Design by<a
                href="#"
              >
                Heritier Ganza Tamba</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
